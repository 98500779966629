export default [
	{
	title: 'Accueil',
	route: 'home',
	icon: 'HomeIcon',
	},
	{
	title: 'BOXEA',
	route: 'boxea',
	icon: '',
	},
	{
	title: 'IZIBOX',
	route: 'izibox',
	icon: '',
	},
	{
	title: 'DOMICIALIZ',
	route: 'domicializ',
	icon: '',
	},
]
