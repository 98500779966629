<template>
	<b-nav-item-dropdown
	right
	toggle-class="d-flex align-items-center dropdown-user-link"
	class="dropdown-user"
	>
		<template #button-content>
		  <div class="d-sm-flex d-none user-nav">
			<p class="user-name font-weight-bolder mb-0">
			  {{ userData.username }} {{ userData.lastname }}
			</p>
			<span class="user-status">{{ userData.role }}</span>
		  </div>
		  <b-avatar
			size="40"
			:src="userData.avatar"
			variant="light-primary"
			badge
			class="badge-minimal"
			badge-variant="success"
		  >
			<feather-icon
			  v-if="!userData.fullName"
			  icon="UserIcon"
			  size="22"
			/>
		  </b-avatar>
		</template>
    <b-dropdown-item
      :to="{ name: 'pages-account-setting'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>
		<b-dropdown-item
		  link-class="d-flex align-items-center"
		  @click="resetpass()"
		>
		  <feather-icon
			size="16"
			icon="KeyIcon"
			class="mr-50"
		  />
		  <span>Changer mon mot de passe</span>
		</b-dropdown-item>
		<b-dropdown-item
		  link-class="d-flex align-items-center"
		  @click="logout"
		>
		  <feather-icon
			size="16"
			icon="LogOutIcon"
			class="mr-50"
		  />
		  <span>{{ $t('Logout') }}</span>
		</b-dropdown-item>
	</b-nav-item-dropdown>
</template>
<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  data() {
    return {
    	userData: JSON.parse(localStorage.getItem('userData')),
		urlstripe: {},
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
    resetpass() {
		this.$http.post('/auth/forgotpassword', { email: this.userData.email })
			.then(() => {
				// const { rData } = response.data.response
				// console.log(rData)
				this.$toast({
				component: ToastificationContent,
				props: {
				  title: 'Un email vous a été envoyé.',
				  icon: 'EditIcon',
				  variant: 'success',
				},
			  })
			})
    },
}
</script>
